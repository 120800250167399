import PropTypes from 'prop-types'
import classNames from 'classnames'

import { formatCurrency } from '@saatva-bits/pattern-library.utils.price-format'

import styles from './PriceRange.module.scss'

const formattedPrice = (lowPrice, highPrice) => {
    const hasRange = highPrice > 0 && lowPrice !== highPrice
    return hasRange
        ? `${formatCurrency(lowPrice)} - ${formatCurrency(highPrice)}`
        : formatCurrency(lowPrice)
}

const getFormattedPriceRanges = (fullPriceMin, fullPriceMax, finalPriceMin, finalPriceMax) => {
    const isDiscounted = (fullPriceMin && fullPriceMax) && (finalPriceMin < fullPriceMin || finalPriceMax < fullPriceMax )
    const fullPrice = isDiscounted && formattedPrice(fullPriceMin, fullPriceMax)
    const finalPrice = formattedPrice(finalPriceMin, finalPriceMax)

    return {
        fullPrice,
        finalPrice
    }
}

const PriceRange =({
    fullPriceMin,
    fullPriceMax,
    finalPriceMin,
    finalPriceMax,
    containerClassName,
    finalPriceClassName,
    fullPriceClassName
}) => {
    const { fullPrice, finalPrice } = getFormattedPriceRanges(fullPriceMin, fullPriceMax, finalPriceMin, finalPriceMax)

    const containerClassNames = classNames(styles.container, containerClassName)
    const finalPriceClassNames = classNames(styles.finalPrice, finalPriceClassName)
    const fullPriceClassNames = classNames(styles.fullPrice, fullPriceClassName)

    return (
        <div className={containerClassNames}>
            {finalPrice && <div className={finalPriceClassNames} data-selector="finalPrice">{finalPrice}</div>}
            {fullPrice && <div className={fullPriceClassNames} data-selector="fullPrice">{fullPrice}</div>}
        </div>
    )
}

PriceRange.propTypes = {
    fullPriceMin: PropTypes.number.isRequired,
    fullPriceMax: PropTypes.number.isRequired,
    finalPriceMin: PropTypes.number.isRequired,
    finalPriceMax: PropTypes.number.isRequired,
    containerClassName: PropTypes.string,
    finalPriceClassName: PropTypes.string,
    fullPriceClassName: PropTypes.string,
}

export default PriceRange
