import { useState } from 'react'
import PropTypes from 'prop-types'

import { Button } from '@saatva-bits/pattern-library.components.button'
import { useAttributeOptions, useProductState, useProductData } from '@saatva-bits/pattern-library.modules.selection'
import { Loader } from '@saatva-bits/pattern-library.components.loader'
import { useSharedCartService } from '@saatva-bits/pattern-library.modules.cart'

import SoldOutMessage from '../SoldOutMessage'
import QuantityInput from './partials/QuantityInput'

import styles from './AddToCartButton.module.scss'

const getAddToCartItem = (product, variant) => {
    const { quantity, magentoBundleOptions, category } = variant
    const { name, productCode } = product
    const type = magentoBundleOptions ? 'bundle' : 'simple'

    const bundleOptions = magentoBundleOptions
        ? Array.isArray(magentoBundleOptions)
            ? magentoBundleOptions[0]
            : magentoBundleOptions
        : null

    
    return {
        ...variant,
        type,
        quantity: parseInt(quantity),
        category: category && category.toLowerCase(),
        parentSku: productCode,
        label: name,
        name,
        bundleOptions
    }
}

/**
 *
 * @param {{
 *  productCode: string,
 *  onAddToCart: () => void,
 * }} props
 * @returns
 */
const AddToCartButton = ({
    children,
    productCode,
    hasQtySelector,
    onAddToCart
}) => {
    const primaryProductState = useProductState(productCode, [
        'category',
        'inStock',
        'quantity',
        'price',
        'sku',
        'productCode',
        'bundledVariants',
        'magentoBundleOptions',
        'attributes',
        'subcategory',
        'name',
        'genericName'
    ])
    const product = useProductData(productCode)

    let itemsForAddToCart = [getAddToCartItem(product, primaryProductState)]
    
    if (primaryProductState.bundledVariants && primaryProductState.bundledVariants.length) {
        itemsForAddToCart = primaryProductState.bundledVariants.map(partialVariant => {
            const fullProduct = product.bundledProducts.find(product => product.productCode === partialVariant.productCode)

            if (fullProduct) {
                const fullVariant = fullProduct.variants.find(variant => variant.sku === partialVariant.sku)

                if (fullVariant) {
                    return getAddToCartItem(fullProduct, {
                        ...fullVariant,
                        quantity: Number(partialVariant.quantity)
                    })
                }
            }
        }).filter(Boolean)
    }

    const totalQuantity = itemsForAddToCart.reduce((accumulator, currentItem) => {
        return accumulator + currentItem.quantity
    }, 0)

    const quantityAttribute = useAttributeOptions(productCode, 'quantity')
    const { addItemToCart } = useSharedCartService()

    const [isLoading, setIsLoading] = useState(false)
    const [buttonText, setButtonText] = useState(children)

    const dimension = `${primaryProductState.category} plp`
    
    const handleAddToCartButton = async (items) => {
        setIsLoading(true)
        await addItemToCart(items, null, dimension, primaryProductState.bundledVariants ? primaryProductState : null)
        const itemsToCart = totalQuantity > 1 ? 'Items to Cart' : 'Item to Cart'
        setButtonText(`Added ${totalQuantity} ${itemsToCart}`)
        onAddToCart && onAddToCart()
        setIsLoading(false)
    }

    if (primaryProductState.inStock) {
        return (
            <div className={styles['container']}>
                {hasQtySelector && quantityAttribute &&
                    <QuantityInput quantityAttribute={quantityAttribute} dataSelector='QtySelector' />
                }
                <Button
                    type="button"
                    kind="primary"
                    className={styles['addButton']}
                    onClick={() => {
                        handleAddToCartButton(itemsForAddToCart)
                    }}
                >
                    {isLoading && <Loader className={styles['loader']} itemClassName={styles['loaderDots']} />}
                    {buttonText}
                </Button>
            </div>
        )
    }
    return <SoldOutMessage className={`${styles['addButton']} ${styles['addButton-soldOut']}`} />
}

AddToCartButton.propTypes = {
    productCode: PropTypes.string,
    onAddToCart: PropTypes.func
}

export default AddToCartButton
